<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="WORK_IMPORTANT_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="workImportantCd"
            label="작업중요도"
            v-model="searchParam.workImportantCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="정비요청 목록"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="500px"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="single"
      rowKey="mdmSwsId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        plantCd: null,
        requestDts: [],
        workDeptCd: '',
        reqDeptCd: '',
        woWorkReqStepCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          // {
          //   name: 'swsNo',
          //   field: 'swsNo',
          //   label: '정비작업표준 관리번호',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 160px',
          // },
          {
            name: 'swsName',
            field: 'swsName',
            label: '정비작업표준명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'workImportantName',
            field: 'workImportantName',
            label: '작업중요도',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'termTm',
            field: 'termTm',
            label: '작업예상시간',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'purpose',
            field: 'purpose',
            label: '목적',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.searchParam.plantCd = this.popupParam.plantCd;
      this.listUrl = selectConfig.mdm.sws.mst.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'grey-6';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'orange';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'blue';
          break;
        case 'WWPSC00004': // 작업결과 승인중
          returnColor = 'yellow';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'deep-purple-6';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'green';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'red-6';
          break;
      }

      return returnColor;
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '작업표준의 복사할 대상을 선택하세요.', // 정비작업표준을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
